.position {
  text-transform: uppercase;
  background-image: linear-gradient(-225deg,
      #231557 0%,
      #44107a 29%,
      #ff1361 67%,
      #fff800 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  /* font-size: 190px; */
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

#colorlib-aside {
  height: 100vh;
  /* This sets the sidebar to be as tall as the viewport */
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* This will enable scrolling inside the sidebar if the content is taller than the screen */
  padding-bottom: 0;
  margin-bottom: 0;
}


.colorlib-footer {
  margin-top: auto;
  text-align: center;
}